import {css, html, LitElement} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

@customElement('copy-button')
export class CopyButton extends LitElement {
  static override styles = css`
    .overlay {
      background-color: transparent;
      border: 1px solid #efefef;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      height: 36px;
      padding: 0.5rem;
      width: 72px;
    }

    .overlay:hover {
      background-color: #E9E9E9;
    }

    .overlay:active {
      background-color: var(--orange-300);
    }

    .overlay:active .copy-label,
    .overlay:active sl-copy-button::part(button) {
      color: var(--white);
    }

    .overlay:active sl-copy-button img {
      filter: brightness(0) invert(1);
    }

    .copy-label {
      color: var(--gray-300);
      font-size: 12px;
      line-height: 19.2px;
    }

    .shoelace-button::part(button) {
      padding: 0;
    }
  `;

  @property({type: String}) value = '';
  @query('sl-copy-button') private copyButtonElement?: HTMLElement;

  override render() {
    return html`
        <div class="overlay" @click="${this.handleOverlayClick}">
            <span class="copy-label">Copy</span>
            <sl-copy-button class="shoelace-button" .value="${this.value}">
                <img slot="copy-icon" src="../../static/images/copy-button.svg" alt="Copy">
            </sl-copy-button>
        </div>
    `;
  }

  private handleOverlayClick() {
    const button = this.copyButtonElement?.shadowRoot?.querySelector('button');
    button?.click();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'copy-button': CopyButton;
  }
}